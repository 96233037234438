import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import { geographies } from '~/assets/geographies';
export default defineComponent({
  props: {
    outcome: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    return {
      geographies: geographies
    };
  },
  computed: {},
  methods: {
    findGeographyNames: function findGeographyNames(recordIds) {
      var _this = this;
      if (!recordIds) {
        return [];
      }
      return recordIds.map(function (recordId) {
        var found = _this.geographies.find(function (geography) {
          return geography.id === recordId;
        });
        if (found) return found.name;
        return '';
      });
    }
  }
});